import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

export default function FourOhFourPage() {
  return (
    <Layout>
      <SEO title="Page Not Found" />
      <div className="container">
        <h1 className="page-title">Page Not Found</h1>
        <hr />
        <div className="the-content">
          <p>
            Hi, this page was not found. If you are looking for something in
            particular, please feel free to{' '}
            <Link to="/contact">get in contact</Link>.
          </p>
        </div>
      </div>
    </Layout>
  );
}
